import { useState, useEffect, useCallback, useContext } from 'react';
import { useDebounce } from '@uidotdev/usehooks';
import { logger } from '@/libs/utils/logger';
import { AnalyticsContext } from '@/features/arena-data/view';
import { SearchResponse } from '@/root/types/search';
import { usePathname } from 'next/navigation';
import { getLocaleFromPathname } from '@/root/libs/utils/url';

const SEARCH_DELAY = 250; // milliseconds

export const useSearch = (debounceDelay: number = SEARCH_DELAY) => {
	const [query, setQuery] = useState('');
	const [results, setResults] = useState<any[] | null>(null);
	const [isMalicious, setIsMalicious] = useState<boolean>(false);
	const [isSearching, setIsSearching] = useState<boolean>(false);
	const debouncedQuery = useDebounce(query, debounceDelay);
	const { AITracks } = useContext(AnalyticsContext);

	const pathname = usePathname();
	const locale = getLocaleFromPathname(pathname);
	const runSearch = useCallback(
		async (searchQuery: string) => {
			if (!searchQuery) {
				setResults([]);
			} else {
				try {
					const fetchRes = await fetch(`/${locale}/api/search?query=${searchQuery}`, {
						headers: {
							Accept: 'application/json',
						},
					});

					const searchRes: SearchResponse = await fetchRes.json();

					setResults(searchRes.results);
					setIsMalicious(searchRes.isMalicious);
				} catch (e) {
					logger.error(e);
					setResults([]);
				} finally {
					setIsSearching(false);
				}
			}
		},
		[debouncedQuery],
	);

	useEffect(() => {
		setIsSearching(true);
		if (debouncedQuery.length > 2) {
			AITracks.searchBoxQuery(debouncedQuery);
			runSearch(debouncedQuery);
		} else {
			return;
		}
	}, [debouncedQuery, runSearch]);

	return { query, setQuery, results, isSearching, isMalicious };
};

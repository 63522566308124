import { createEffect, createStore, sample } from 'effector';
import { FingerprintService } from '.';
import { gdprConsentUpdated } from '../model';
import { TFingerPrintStore } from '@/root/types/Fingerprint';

export const initFPStore = {
	fpVisitorId: 'unknown',
	fpIncognito: false,
	fpCheckDone: false,
};

export const fingerprintDetectFx = createEffect(async () => await FingerprintService.init());
export const fingerprintRemoveFx = createEffect(() => FingerprintService.remove());

export const $FingerprintStore = createStore<TFingerPrintStore>(initFPStore)
	.on(fingerprintDetectFx.done, (_, data) => {
		try {
			if (data.result) {
				return { ...JSON.parse(data.result), fpCheckDone: true };
			}
			return initFPStore;
		} catch {
			return initFPStore;
		}
	})
	.on(fingerprintRemoveFx.done, (_, data) => initFPStore);

const $isInitialized = createStore<boolean>(false)
	.on(fingerprintDetectFx.done, (_, data) => true)
	.on(fingerprintRemoveFx.done, (_, data) => false);

sample({
	clock: gdprConsentUpdated,
	source: $isInitialized,
	filter: (isInitialized, gdprConsent) => {
		const result = !isInitialized && gdprConsent === true;
		return result;
	},
	target: fingerprintDetectFx,
});
sample({
	clock: gdprConsentUpdated,
	filter: (gdprConsent) => gdprConsent !== true,
	target: fingerprintRemoveFx,
});
